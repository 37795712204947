<template>
  <div v-if="mountCompleted">
    <el-tabs v-model="activeNameForExcelImport">
      <el-tab-pane label="Success" name="success">
        <span slot="label">
          Success
          <span style="color: #071aed">
            {{ successedExcelImportData.length }}
          </span>
        </span>
        <div class="table-container" style="width: 100%; overflow-x: auto">
          <el-table
            :data="successedExcelImportDataPagination()"
            border
            @cell-mouse-enter="editSuccessedExcelImportData"
            @cell-mouse-leave="resetSuccessedExcelImportData"
            style="width: 100%; overflow-x: auto"
            v-loading="isTableDataLoading"
            element-loading-text="Loading..."
          >
            <el-table-column
              v-for="(row, index) in foundArray"
              :key="index"
              :label="row.label"
              :width="220"
            >
              <template slot-scope="scope">
                <div v-if="row && row.input_type == 'SIGNATURE'">
                  <img
                    v-if="
                      scope.row[row.template_id] &&
                      scope.row[row.template_id][row.key]
                    "
                    :src="scope.row[row.template_id][row.key]"
                  />
                </div>
                <div v-else-if="row && row.input_type == 'DATE'">
                  <el-date-picker
                    v-if="getDateViewType(row)"
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                    :type="getDateViewType(row)"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-else
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                  >
                  </el-date-picker>
                </div>
                <div
                  v-else-if="row && row.input_type == 'TIME' && row.is_fixed"
                >
                  <el-time-select
                    v-model="scope.row[row.template_id][row.key]"
                    :picker-options="getTimeProperties(row)"
                    :placeholder="row.placeholder || row.label"
                    prefix-icon="el-icon-time"
                    class="eb-ctm-input-time"
                  >
                  </el-time-select>
                </div>
                <div v-else-if="row && row.input_type == 'TIME'">
                  <el-time-picker
                    prefix-icon="el-icon-time"
                    v-model="scope.row[row.template_id][row.key]"
                    :picker-options="{
                      format: 'HH:mm:ss',
                    }"
                    :value-format="'HH:mm:ss'"
                    class="eb-ctm-input-time"
                  ></el-time-picker>
                </div>
                <div v-else-if="row && row.input_type == 'DATE_TIME'">
                  <el-date-picker
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                    :format="'MM-dd-yyyy HH:mm:ss'"
                    type="datetime"
                  >
                  </el-date-picker>
                </div>
                <div v-else-if="row && row.input_type == 'DATE_TIME_RANGE'">
                  <el-date-picker
                    type="datetimerange"
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                  >
                  </el-date-picker>
                </div>
                <div v-else-if="row && row.input_type == 'DATE_RANGE'">
                  <el-date-picker
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                    type="daterange"
                  >
                  </el-date-picker>
                </div>
                <div v-else-if="row && row.input_type == 'TIME_RANGE'">
                  <el-time-picker
                    is-range
                    prefix-icon="el-icon-time"
                    end-placeholder="End time"
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                  >
                  </el-time-picker>
                </div>
                <!-- <div v-else-if="row && row.input_type == 'TIME'">
                  <el-time-picker
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select time"
                  >
                  </el-time-picker>
                </div> -->
                <div v-else-if="row && row.input_type == 'ENTITY'">
                  <el-input
                    :disabled="checkIsEntityDisabled(scope.row, row, 'input')"
                    v-model="scope.row[row.template_id][row.key]"
                    clearable
                  >
                    or
                  </el-input>
                  <el-select
                    :disabled="checkIsEntityDisabled(scope.row, row, 'select')"
                    clearable
                    collapse-tags
                    filterable
                    remote
                    :loading="entityFieldLoading"
                    loading-text="fetching..."
                    :remote-method="(query) => searchEntityData(query, row)"
                    @clear="searchEntityData('', row)"
                    @change="(value) => setLabelToForm(value,scope.row,row)"
                    v-model="scope.row[row.template_id][row.key + '/existed']"
                    placeholder="enter to search"
                  >
                    <el-option
                      v-for="(temp, i) in getCurrentEntityOptions(
                        row.entity_id
                      )"
                      :key="i"
                      :value="temp._id"
                      :label="getLabel(temp, i, row)"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="row && row.input_type == 'SELECT'">
                  <el-select
                    clearable
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                    v-if="!row.is_global_variable"
                  >
                    <el-option
                      v-for="item in row.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    clearable
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                    v-else
                  >
                    <el-option
                      v-for="item in globalVariables[
                        `${row.template_id}#${row.key}`
                      ]"
                      :key="item"
                      :value="item"
                      :label="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-else-if="row && row.input_type == 'MULTI_SELECT'">
                  <el-select
                    multiple
                    collapse-tags
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                    v-if="!row.is_global_variable"
                  >
                    <el-option
                      v-for="item in row.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    multiple
                    collapse-tags
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                    v-else
                  >
                    <el-option
                      v-for="item in globalVariables[
                        `${row.template_id}#${row.key}`
                      ]"
                      :key="item"
                      :value="item"
                      :label="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-else-if="row && row.input_type == 'FORMULA'">
                  <el-input v-model="row.formula" :disabled="true"> </el-input>
                </div>
                <div v-else-if="row && row.input_type == 'NUMBER'">
                  <el-input
                    v-model="scope.row[row.template_id][row.key]"
                    type="number"
                  >
                  </el-input>
                </div>
                <div v-else-if="row && row.input_type == 'PHONE_COUNTRY_CODE'">
                  <VuePhoneNumberInput
                    class="vue-phone-validator"
                    v-model="scope.row[row.template_id][row.key]"
                    :default-country-code="
                      scope.row[row.template_id][row.key + '_code']
                        ? scope.row[row.template_id][row.key + '_code']
                            .countryCode
                        : row.defaultCountryCode
                    "
                  />
                </div>
                <div v-else-if="row && row.inputType == 'CHECKBOX'">
                  <el-checkbox v-model="scope.row[row.template_id][row.key]">
                  </el-checkbox>
                </div>
                <div v-else>
                  <el-input v-model="scope.row[row.template_id][row.key]">
                  </el-input>
                </div>
                <span
                  style="font-size: 10px; color: red; font-weight: 700"
                  v-if="
                    checkFieldData(scope.row[row.template_id][row.key], row) &&
                    !checkFieldData(scope.row[row.template_id][row.key], row)
                      .success
                  "
                >
                  {{
                    checkFieldData(scope.row[row.template_id][row.key], row)
                      .message
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          style="text-align: center"
          @size-change="handleSizeChangeForIEDS"
          @current-change="handleCurrentChangeForIEDS"
          :current-page.sync="currentPageForIEDS"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageSizeForIEDS"
          layout="prev, pager, next, jumper, total"
          :total="successedExcelImportData.length"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="Errors" name="errors">
        <span slot="label">
          Errors
          <span style="color: #f21307">
            {{ this.errorExcelImportData.length }}
          </span>
        </span>
        <div class="table-container" style="width: 100%; overflow-x: auto">
          <el-table
            :data="errorExcelImportDataPagination()"
            border
            @cell-mouse-enter="editErrorExcelImportData"
            @cell-mouse-leave="resetErrorExcelImportData"
            style="width: 100%; overflow-x: auto"
          >
            <el-table-column
              v-for="(row, index) in foundArray"
              :key="index"
              :label="row.label"
              :width="220"
            >
              <template slot-scope="scope">
                <div v-if="row && row.inputType == 'DATE'">
                  <el-date-picker
                    style="width: 200px"
                    v-model="scope.row[row.template_id][row.key]"
                  >
                  </el-date-picker>
                </div>
                <div v-else-if="row && row.inputType == 'SELECT'">
                  <el-select
                    clearable
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in row.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-else-if="row && row.inputType == 'MULTI_SELECT'">
                  <el-select
                    multiple
                    collapse-tags
                    v-model="scope.row[row.template_id][row.key]"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in row.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-else-if="row && row.inputType == 'FORMULA'">
                  <el-input v-model="row.formula" :disabled="true"> </el-input>
                </div>
                <div v-else-if="row && row.inputType == 'NUMBER'">
                  <el-input
                    v-model="scope.row[row.template_id][row.key]"
                    type="number"
                  >
                  </el-input>
                </div>
                <div v-else>
                  <el-input v-model="scope.row[row.template_id][row.key]">
                  </el-input>
                </div>
                <span
                  style="font-size: 10px; color: red; font-weight: 700"
                  v-if="
                    checkFieldData(scope.row[row.template_id][row.key], row) &&
                    !checkFieldData(scope.row[row.template_id][row.key], row)
                      .success
                  "
                >
                  {{
                    checkFieldData(scope.row[row.template_id][row.key], row)
                      .message
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          style="text-align: center"
          @size-change="handleSizeChangeForIE"
          @current-change="handleCurrentChangeForIE"
          :current-page.sync="currentPageForIE"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageSizeForIE"
          layout="prev, pager, next, jumper, total"
          :total="errorExcelImportData.length"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import EntitiesHelper from "../../mixins/EntitiesHelper";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
import CustomDashboardHelper from "../../mixins/CustomDashboardHelper";
export default {
  name: "entities-SuccessAndErrorsTable",
  components: {
    VuePhoneNumberInput: () => import("vue-phone-number-input"),
  },
  mixins: [EntitiesHelper, CustomDashboardHelper],
  props: [
    "templatesData",
    "currentEntity",
    "foundArray",
    "successedExcelImportData",
    "allEntities",
    "companyAllTemplateFields",
  ],
  async mounted() {
    this.loading = true;
    // this.makeSuccessAndErrorData()
    this.getGlobalVariablesData();
    (this.foundArray || []).map((fd) => {
      this.fieldsObject[`${fd.template_id}#${fd.key}`] = fd;
    });
    await this.setTemplateData();
    this.loading = false;
    this.mountCompleted = true;
  },
  data() {
    return {
      isTableDataLoading: false,
      mountCompleted : false,
      entityFieldLoading: false,
      activeNameForExcelImport: "success",
      errorExcelImportData: [],
      pageSizeForIEDS: 5,
      currentPageForIEDS: 1,
      pageSizeForIE: 5,
      currentPageForIE: 1,
      fieldsObject: {},
      standardTemp: {},
      loading: false,
      globalVariables: {},
      allEntityFieldsDataObj: {},
      allEntityFieldsObj: {},
    };
  },
  methods: {
    getDateViewType(row) {
      if (row.date_view_type && row.date_view_type != "day") {
        return row.date_view_type;
      }
      return null;
    },
    makeSuccessAndErrorData() {
      if (this.templatesData.length) {
        let standardTemp;
        let standardTempId;
        if (this.currentEntity.entity_type === "INDIVIDUAL") {
          standardTemp = this.currentEntity.templates.find((temp) => {
            if (
              (temp &&
                temp.template_id &&
                temp.template_id.type === "STANDARD") ||
              (temp &&
                temp.templateInfo &&
                temp.templateInfo.type === "STANDARD")
            ) {
              return true;
            }
          });
          this.standardTemp = standardTemp;
          if (this.templatesData[0][standardTemp._id]) {
            standardTempId = standardTemp._id;
          } else {
            standardTempId = standardTemp.template_id;
          }
        }
        this.templatesData.forEach((rowData) => {
          let fieldsObject = {};
          this.foundArray.map((e) => {
            fieldsObject[e.key] = e;
          });
          let isAllowed = true;
          (Object.keys(rowData) || []).map((row) => {
            if (!isAllowed) return;
            let eachTemplateData = rowData[row];
            (Object.keys(eachTemplateData) || []).map((key) => {
              if (!isAllowed) return;
              let field = fieldsObject[key];
              if (field?.inputType == "AUTO_INCREMENT_NUMBER") {
                let autoIncrementCheck = this.checkAutoIncrementData(
                  eachTemplateData[key],
                  field.auto_increment_prefix,
                  field.auto_increment_starting_value ||
                    field.auto_increment_starting_number
                );
                if (!autoIncrementCheck.success) isAllowed = false;
              }
            });
          });
          if (!isAllowed) {
            return this.errorExcelImportData.push(rowData);
          }
          if (this.currentEntity.entity_type === "INDIVIDUAL") {
            if (
              rowData[standardTemp._id] ||
              rowData[standardTemp.template_id]
            ) {
              if (
                ((Object.keys(rowData[standardTempId]).indexOf("first_name") !==
                  -1 &&
                  Object.keys(rowData[standardTempId]).indexOf("last_name") !==
                    -1) ||
                  Object.keys(rowData[standardTempId]).indexOf("name") !==
                    -1) &&
                Object.keys(rowData[standardTempId]).indexOf("email") !== -1
              ) {
                if (!rowData[standardTempId]["first_name"]) {
                  if (!rowData[standardTempId]["name"]) {
                    this.errorExcelImportData.push(rowData);
                    return;
                  }
                }
                if (!rowData[standardTempId]["last_name"]) {
                  if (!rowData[standardTempId]["name"]) {
                    this.errorExcelImportData.push(rowData);
                    return;
                  }
                }
                // if (Object.keys(rowData[standardTempId]).indexOf('phone_number') !== -1) {
                //     if (rowData[standardTempId]['phone_number'] !== '') {
                //         const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
                //         if (!phoneValidation.test(rowData[standardTempId]['phone_number'])) {
                //             this.errorExcelImportData.push(rowData);
                //             return
                //         }
                //     }
                // }
                if (
                  rowData[standardTempId]["email"] !== "" &&
                  rowData[standardTempId]["email"] !== undefined &&
                  rowData[standardTempId]["email"] !== null
                ) {
                  if (
                    rowData[standardTempId]["email"].toString() ===
                    this.getAuthenticatedUser.email.toString()
                  ) {
                    this.errorExcelImportData.push(rowData);
                    return;
                  }
                  var mailformat =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (
                    // rowData[standardTempId]["email"].match(
                    //     mailformat
                    // ) === null
                    !mailformat.test(rowData[standardTempId]["email"])
                  ) {
                    this.errorExcelImportData.push(rowData);
                    return;
                  }
                } else {
                  this.errorExcelImportData.push(rowData);
                  return;
                }
              } else {
                this.errorExcelImportData.push(rowData);
                return;
              }
            } else {
              //if standrd temp not found
              this.errorExcelImportData.push(rowData);
              return;
            }
            this.successedExcelImportData.push(rowData);
          } else if (
            this.currentEntity.entity_type === "GENERAL" ||
            this.currentEntity.entity_type === "BUSINESS"
          ) {
            //need to write primary fields conditions
            let result = this.currentEntity.primaryFields.every((pr) => {
              if (
                pr.template_id &&
                rowData[pr.template_id] &&
                rowData[pr.template_id][pr.key]
              ) {
                return true;
              }
            });
            if (result) {
              this.successedExcelImportData.push(rowData);
            } else {
              this.errorExcelImportData.push(rowData);
            }
          }
        });
      }
    },
    async setTemplateData() {
      (this.templatesData || []).map((data) => {
        (Object.keys(data) || []).map((tempId) => {
          let eachTempData = data[tempId];
          (Object.keys(eachTempData) || []).map((key) => {
            let field = this.fieldsObject[`${tempId}#${key}`];
            if (field?.input_type && eachTempData[key]) {
              let replaceValue = eachTempData[key];
              switch (field.input_type) {
                case "DATE_RANGE":
                case "DATE_TIME_RANGE":
                case "TIME_RANGE":
                  if (!Array.isArray(eachTempData[key])) {
                    let separator = this.getSeparator(eachTempData[key]);
                    if (!separator) break;
                    replaceValue = eachTempData[key].split(separator);
                    replaceValue = replaceValue.map((e) => {
                      if (field.input_type == "TIME_RANGE") {
                        return moment(e, "HH:mm:ss").toISOString();
                      } else {
                        return moment(e).toISOString();
                      }
                    });
                  }
                  break;
                case "TIME":
                  if (
                    field.is_fixed &&
                    eachTempData[key]?.split(":")?.length > 2
                  ) {
                    replaceValue = eachTempData[key].substring(0, 5);
                  } else if (!field.is_fixed) {
                    replaceValue = moment(eachTempData[key], "HH:mm:ss").format(
                      "HH:mm:ss"
                    );
                  }
                  break;
                case "DATE":
                  replaceValue = moment(
                    eachTempData[key],
                    "MM-DD-YYYY"
                  ).toISOString();
                  break;
                case "DATE_TIME":
                  replaceValue = moment(
                    eachTempData[key],
                    "MM-DD-YYYY HH:mm:ss"
                  ).toISOString();
                  break;
                default:
                  replaceValue = eachTempData[key];
                  break;
              }
              eachTempData[key] = replaceValue;
            }
          });
        });
      });
      this.checkForDuplicateData();
      await this.getEntityFieldsData();
    },
    getSeparator(string) {
      let separator = "";
      if (string.includes(" to ")) {
        separator = " to ";
      } else if (string.includes(" TO ")) {
        separator = " TO ";
      } else if (string.includes(" - ")) {
        separator = " - ";
      }
      return separator;
    },
    checkForDuplicateData() {
      let dupRulesTemplates = this.currentEntity.templates.filter(
        (temp) => temp?.templateInfo?.all_duplicate_rules?.length
      );
      if (dupRulesTemplates?.length) {
        let allTempResults = {};
        dupRulesTemplates.map((temp) => {
          let allRuleResults = [];
          temp.templateInfo.all_duplicate_rules.map((rule) => {
            let result = this.checkConditionsForSingleTemplate(
              rule,
              this.templatesData,
              temp.template_id
            );
            allRuleResults.push(result);
          });
          let duplicateDataIndexes = [];
          let allRulesArraysArray = allRuleResults.map((obj) => {
            return (Object.values(obj) || []).flat();
          });
          if (temp.templateInfo.duplicate_query_type == "$and") {
            duplicateDataIndexes = allRulesArraysArray.reduce((acc, arr) =>
              acc.filter((value) => arr.includes(value))
            );
          } else if (temp.templateInfo.duplicate_query_type == "$or") {
            duplicateDataIndexes = [
              ...new Set(allRulesArraysArray?.flat() || []),
            ];
          }
          //Just for reference purpose saving it as object
          allTempResults[temp.template_id] = duplicateDataIndexes;
        });
        let dupIndexes = Object.values(allTempResults)
          ?.flat()
          ?.sort((a, b) => b - a);
        if (dupIndexes?.length) {
          dupIndexes.forEach((index) => {
            this.templatesData.splice(index, 1);
          });
          this.$message.warning(
            `Some data (${dupIndexes?.length}) has been removed because it contained duplicates.`
          );
        }
      }
      this.makeSuccessAndErrorData();
    },
    checkConditionsForSingleTemplate(rules, data, tempId) {
      if (data && data.length > 1) {
        let selectedFields = rules.map((e) => e.selected_field);
        return this.checkCombinationDuplicates(
          data || {},
          selectedFields,
          tempId
        );
      }
      return {};
    },
    checkCombinationDuplicates(objArray, propertyNames, tempId) {
      const combinations = objArray.map((obj) =>
        propertyNames.map((prop) => obj[tempId]?.[prop])?.join("-")
      );
      //Saving them as label and indexes for future ref
      let duplicationDataIndexObj = {};
      combinations.map((combination, index, arr) => {
        if (
          !["", null, undefined].includes(combination) &&
          arr.indexOf(combination) !== index
        ) {
          if (!duplicationDataIndexObj[combination]) {
            duplicationDataIndexObj[combination] = [index];
          } else {
            duplicationDataIndexObj[combination].push(index);
          }
        }
      });

      return duplicationDataIndexObj;
    },
    validateSelectedRow(rowData) {
      //1st part
      let entity = this.currentEntity;
      let standardTemp;
      let standardTempId;
      if (entity.entity_type === "INDIVIDUAL") {
        standardTemp = entity.templates.find((temp) => {
          if (
            (temp &&
              temp.template_id &&
              temp.template_id.type === "STANDARD") ||
            (temp && temp.templateInfo && temp.templateInfo.type === "STANDARD")
          ) {
            return true;
          }
        });
        if (rowData[standardTemp._id]) {
          standardTempId = standardTemp._id;
        } else {
          standardTempId = standardTemp.template_id;
        }
      }

      //second part
      if (rowData) {
        let fieldsObject = {};
        this.foundArray.map((e) => {
          fieldsObject[e.key] = e;
        });
        let isAllowed = true;
        (Object.keys(rowData) || []).map((row) => {
          if (!isAllowed) return;
          let eachTemplateData = rowData[row];
          (Object.keys(eachTemplateData) || []).map((key) => {
            if (!isAllowed) return;
            let field = fieldsObject[key];
            if (field?.inputType == "AUTO_INCREMENT_NUMBER") {
              let autoIncrementCheck = this.checkAutoIncrementData(
                eachTemplateData[key],
                field.auto_increment_prefix,
                field.auto_increment_starting_value ||
                  field.auto_increment_starting_number
              );
              if (!autoIncrementCheck.success) isAllowed = false;
            }
          });
        });
        if (!isAllowed) {
          return false;
        }
        if (entity.entity_type === "INDIVIDUAL") {
          if (rowData[standardTemp._id] || rowData[standardTemp.template_id]) {
            // if (Object.keys(rowData[standardTempId]).indexOf('phone_number') !== -1) {
            //     if (rowData[standardTempId]['phone_number'] !== '') {
            //         const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
            //         if (!phoneValidation.test(rowData[standardTempId]['phone_number'])) {
            //             return {
            //                 success: false
            //             }
            //         }
            //     }
            // }
            if (
              ((Object.keys(rowData[standardTempId]).indexOf("first_name") !==
                -1 &&
                Object.keys(rowData[standardTempId]).indexOf("last_name") !==
                  -1) ||
                Object.keys(rowData[standardTempId]).indexOf("name") !== -1) &&
              Object.keys(rowData[standardTempId]).indexOf("email") !== -1
            ) {
              if (!rowData[standardTempId]["first_name"]) {
                if (!rowData[standardTempId]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (!rowData[standardTempId]["last_name"]) {
                if (!rowData[standardTempId]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (
                rowData[standardTempId]["email"] !== "" &&
                rowData[standardTempId]["email"] !== undefined &&
                rowData[standardTempId]["email"] !== null
              ) {
                if (
                  rowData[standardTempId]["email"].toString() ===
                  this.getAuthenticatedUser.email.toString()
                ) {
                  return {
                    success: false,
                  };
                }
                var mailformat =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (
                  // rowData[standardTempId]["email"].match(
                  //     mailformat
                  // ) === null
                  !mailformat.test(rowData[standardTempId]["email"])
                ) {
                  return {
                    success: false,
                  };
                }
              } else {
                return {
                  success: false,
                };
              }
            } else {
              return {
                success: false,
              };
            }
          } else {
            //No standard Template found
            return {
              success: false,
            };
          }
          return {
            success: true,
          };
        } else if (
          entity.entity_type === "GENERAL" ||
          entity.entity_type === "BUSINESS"
        ) {
          //need to write primary fields conditions
          let result = entity.primaryFields.every((pr) => {
            if (
              pr.template_id &&
              rowData[pr.template_id] &&
              rowData[pr.template_id][pr.key]
            ) {
              return true;
            }
          });
          if (result) {
            return {
              success: true,
            };
          } else {
            return {
              success: false,
            };
          }
        }
      }
      //validate selected row
    },
    editSuccessedExcelImportData(row) {
      this.currentActiveSuccessRowIndex =
        this.successedExcelImportData.indexOf(row);
    },
    resetSuccessedExcelImportData() {
      let selectedRow =
        this.successedExcelImportData[this.currentActiveSuccessRowIndex];
      let isVaild = this.validateSelectedRow(selectedRow);
      if (isVaild && isVaild.success === false) {
        this.errorExcelImportData.push(selectedRow);
        this.successedExcelImportData.splice(
          this.currentActiveSuccessRowIndex,
          1
        );
      }
      this.pageSizeForIEDS = 0;
      this.pageSizeForIEDS = 5;
    },
    editErrorExcelImportData(row) {
      this.currentActiveErrorRowIndex = this.errorExcelImportData.indexOf(row);
    },
    resetErrorExcelImportData() {
      let selectedRow =
        this.errorExcelImportData[this.currentActiveErrorRowIndex];
      let isValid = this.validateSelectedRow(selectedRow);
      if (isValid && isValid.success === true) {
        this.successedExcelImportData.push(selectedRow);
        this.errorExcelImportData.splice(this.currentActiveErrorRowIndex, 1);
      }
    },
    getTimeProperties(field) {
      console.log("Field", field);
      if (field?.properties?.time_range && field?.properties?.step_time) {
        let properties = {};
        properties["start"] = moment(field?.properties?.time_range[0]).format(
          "HH:mm:ss"
        );
        properties["end"] = moment(field?.properties?.time_range[1]).format(
          "HH:mm:ss"
        );
        if (
          field?.properties?.step_time == 60 ||
          field?.properties?.step_time == "60"
        ) {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + field?.properties?.step_time + ":00";
        }
        console.log("properties", properties);
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
    checkFieldData(individualFieldValue, foundedField) {
      if (
        foundedField &&
        (individualFieldValue || foundedField.input_type == "CHECKBOX")
      ) {
        if (
          this.currentEntity.entity_type === "INDIVIDUAL" &&
          foundedField &&
          (foundedField.template_id.toString() ===
            this.standardTemp._id.toString() ||
            foundedField.template_id.toString() ===
              this.standardTemp.template_id.toString())
        ) {
          // if (foundedField.key === 'phone_number' && individualFieldValue.toString() !== '') {
          //     const phoneNumberFormat = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
          //     if (!phoneNumberFormat.test(individualFieldValue)) {
          //         return {
          //             success: false,
          //             data: individualFieldValue,
          //             message: 'Invalid Phone Number'
          //         }
          //     }
          // }
          if (foundedField.key === "email") {
            if (
              individualFieldValue.toString() ===
              this.getAuthenticatedUser.email.toString()
            ) {
              return {
                success: false,
                data: individualFieldValue,
                message: "This is sender email",
              };
            }
            const emailFormat =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailFormat.test(individualFieldValue)) {
              return {
                success: false,
                data: individualFieldValue,
                message: "Invalid Email",
              };
            }
          }
        } else if (foundedField.input_type === "SELECT") {
          individualFieldValue = individualFieldValue.toString();
          let individualFieldValueLower = individualFieldValue.toLowerCase();
          let selectArr = [];
          let options;
          if (!foundedField.is_global_variable) {
            options = foundedField.options;
          } else {
            // let key = foundedField.template_id + '#' + foundedField.key
            // options = this.globalVariables[key]
            options = foundedField.options;
          }
          options.forEach((e) => {
            e = e.trim();
            selectArr.push(e.toLowerCase());
          });
          let isExisted = selectArr.includes(individualFieldValueLower.trim());
          if (isExisted) {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          }
        } else if (foundedField && foundedField.input_type === "MULTI_SELECT") {
          let resultArr = [];
          let options;
          if (!foundedField.is_global_variable) {
            options = foundedField.options;
          } else {
            options =
              this.globalVariables[
                `${foundedField.template_id}#${foundedField.key}`
              ];
          }
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (
              individualFieldValue &&
              individualFieldValue.toString().includes(",")
            ) {
              let multiArr = individualFieldValue
                .toString()
                .split(",")
                .map((e) => e.toLowerCase());
              // let smallMultiArr = [];
              resultArr = options.filter((op) => {
                if (op && multiArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() ==
                    individualFieldValue.toString().toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          return {
            success: true,
            data: resultArr,
          };
        } else if (foundedField && foundedField.inputType === "CHECKBOX") {
          if (typeof individualFieldValue === "boolean") {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: false,
              message: "Entered data not matched",
            };
          }
        } else if (
          foundedField &&
          foundedField.inputType === "CHECKBOX_GROUP"
        ) {
          // let options = [];
          // foundedField.options.forEach((e) => {
          //   options.push(e.toLowerCase());
          // });
          let resultArr = [];
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = foundedField.options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (individualFieldValue.toString().includes(",")) {
              let inputArr = individualFieldValue
                .toString()
                .split(",")
                .map((e) => e.toLowerCase());
              resultArr = foundedField.options.filter((op) => {
                if (op && inputArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = foundedField.options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() ==
                    individualFieldValue.toString().toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          // else {
          //   let isExisted = options.includes(
          //     individualFieldValue.toLowerCase()
          //   );
          //   if (isExisted) {
          //     resultantArr.push(individualFieldValue.toLowerCase());
          //   }
          // }
          if (
            resultArr.length >= foundedField.min_selection &&
            resultArr.length <= foundedField.max_selection
          ) {
            return {
              success: true,
              data: resultArr,
            };
          } else {
            resultArr = [];
            return {
              success: false,
              data: resultArr,
            };
          }
        } else if (foundedField && foundedField.inputType === "DATE") {
          var parsedDate = Date.parse(individualFieldValue);
          if (isNaN(parsedDate)) {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          } else {
            if (typeof individualFieldValue === "number") {
              const d = new Date(
                Math.round((individualFieldValue - 25569) * 86400 * 1000)
              );
              individualFieldValue = d;
            }
            return {
              success: true,
              data: individualFieldValue,
            };
          }
        } else if (
          foundedField &&
          foundedField.inputType == "AUTO_INCREMENT_NUMBER"
        ) {
          let autoIncrementCheck = this.checkAutoIncrementData(
            individualFieldValue,
            foundedField.auto_increment_prefix,
            foundedField.auto_increment_starting_value ||
              foundedField.auto_increment_starting_number
          );
          if (!autoIncrementCheck.success) {
            return {
              success: false,
              message: autoIncrementCheck.message,
              data: "",
            };
          }
        }
        return {
          success: true,
          data: individualFieldValue,
        };
      } else if (foundedField && foundedField.inputType === "NUMBER") {
        if (typeof individualFieldValue !== "number") {
          return {
            success: false,
            message: "Field is not a number",
            data: individualFieldValue,
          };
        } else {
          return {
            success: true,
            data: individualFieldValue,
          };
        }
      } else if (foundedField && foundedField.inputType == "FORMULA") {
        return {
          success: true,
          data: individualFieldValue,
        };
      }
      let primaryFieldsIds = this.currentEntity.primaryFields.map((e) => {
        return e.key;
      });
      return {
        success: false,
        data: "",
        message:
          primaryFieldsIds &&
          primaryFieldsIds.length &&
          primaryFieldsIds.includes(foundedField.key)
            ? "Field is empty (PRIMARY FIELD)"
            : "Field is empty",
      };
    },
    handleSizeChangeForIE(val) {
      this.pageSizeForIE = val;
    },
    handleCurrentChangeForIE(val) {
      this.isTableDataLoading = true;
      this.currentPageForIE = val;
      setTimeout(() => {
        this.isTableDataLoading = false;
      }, 1000);
    },
    handleSizeChangeForIEDS(val) {
      this.pageSizeForIEDS = val;
    },
    handleCurrentChangeForIEDS(val) {
      this.isTableDataLoading = true;
      this.currentPageForIEDS = val;
      setTimeout(() => {
        this.isTableDataLoading = false;
      }, 1000);
    },
    async getGlobalVariablesData() {
      await Promise.all(
        this.foundArray.map(async (e) => {
          if (
            e.is_global_variable == true &&
            (e.input_type == "SELECT" || e.input_type == "MULTI_SELECT")
          ) {
            let params = {
              id: e.global_variable_id,
            };
            // await this.$store.dispatch(
            //   "globalVariables/fetchGlobalVariableById",
            //   params
            // );
           let globalVariable = await fetchGlobalVariableById(params);
            if (globalVariable) {
              this.globalVariables[`${e.template_id}#${e.key}`] =
                globalVariable.options;
            }
          }
        })
      );
    },
    checkAutoIncrementData(value, prefix, startValue) {
      value = value?.toString();
      let errorMessage;
      if (prefix) {
        if (!value?.includes(prefix)) {
          errorMessage = `Prefix is missing (${prefix})`;
        } else {
          if (value == prefix) {
            errorMessage = "Please also enter the value";
          }
          let value1 = value.replace(prefix, "");
          if (["", null, undefined].includes(Number(value1)))
            errorMessage = "Please check the format";
          if (Number(value1) < Number(startValue))
            errorMessage = `Minimum Value is ${startValue}`;
        }
      } else {
        if (["", null, undefined].includes(Number(value)))
          errorMessage = "Please check the format";
        if (Number(value) < Number(startValue))
          errorMessage = `Minimum Value is ${startValue}`;
      }
      if (errorMessage) {
        return {
          success: false,
          message: errorMessage,
        };
      } else {
        return {
          success: true,
          message: "Allowed",
        };
      }
    },
    successedExcelImportDataPagination() {
      const from = (this.currentPageForIEDS - 1) * this.pageSizeForIEDS;
      let to = from + this.pageSizeForIEDS;
      return this.successedExcelImportData.slice(from, to);
    },
    errorExcelImportDataPagination() {
      const from = (this.currentPageForIE - 1) * this.pageSizeForIE;
      let to = from + this.pageSizeForIE;
      return this.errorExcelImportData.slice(from, to);
    },
    async getEntityFieldsData() {
      let entityFields = this.foundArray.filter(
        (e) => e.input_type == "ENTITY" && e.entity_id
      );
      if (!entityFields?.length) return;
      await Promise.all(
        entityFields.map(async (fd) => {
          let selectedEntity = (this.allEntities || []).find(
            (e) => e._id == fd.entity_id
          );
          this.allEntityFieldsObj[fd.entity_id] = selectedEntity;
          if (selectedEntity) {
            this.allEntityFieldsDataObj[fd.entity_id] =
              await this.fetchEntitiesDataForTable(selectedEntity);
          }
        })
      );
    },
    checkIsEntityDisabled(data, row, type) {
      if (data[row.template_id]?.[row.key] && type == "select") {
        return true;
      } else if (
        data[row.template_id]?.[row.key + "/existed"] &&
        type == "input"
      ) {
        data[row.template_id][row.key] = "";
        return true;
      }
      return false;
    },
    getLabel(item, i, field) {
      let name = "";
      this.getEntityPrimaryFields(
        field,
        this.allEntityFieldsObj[field.entity_id]
      ).forEach((pr, index) => {
        let field = this.companyAllTemplateFields?.[pr];
        if (
          field &&
          field.inputType != "DATA_TABLE" &&
          index < 3 &&
          this.getPrimaryName(field, item)
        ) {
          let separator = "";
          if (index != 0) {
            separator = " - ";
          }
          name = name + separator + this.getPrimaryName(field, item);
        }
      });
      if (!name) name = "Data " + i;
      return name;
    },
    getCurrentEntityOptions(entity_id) {
      return this.allEntityFieldsDataObj[entity_id] || [];
    },
    getEntityPrimaryFields(field, entity) {
      if (field.primary_fields && field.primary_fields.length) {
        return field.primary_fields;
      }
      if (entity?.primaryFields) {
        return [
          entity?.primaryFields[0].template_id +
            "#" +
            entity?.primaryFields[0].key,
        ];
      }
      return [];
    },
    async searchEntityData(query, field) {
      try {
        let selectedEntity = this.allEntityFieldsObj[field.entity_id];
        this.entityFieldLoading = true;
        this.allEntityFieldsDataObj[field.entity_id] =
          (await this.fetchEntitiesDataForTable(
            selectedEntity,
            1,
            500,
            query
          )) || [];
        this.entityFieldLoading = false;
      } catch (err) {
        this.entityFieldLoading = false;
        console.log("err", err);
      }
    },
    setLabelToForm(value, data, field) {
      let valuesArray = this.getCurrentEntityOptions(field.entity_id);
      let currentData = (valuesArray || []).find(e => e._id == value);
      if(currentData && data[field.template_id]) {
        data[field.template_id][`${field.key}/name`] = this.getLabel(currentData, 0, field);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
  },
  watch: {
    currentEntity: {
      handler() {
        this.makeSuccessAndErrorData();
      },
      deep: true,
    },
  },
};
</script>
